<template>
  <div>
    <div class="card rounded-0 m-0">
        <div class="card-header px-1 py-half bg-light-primary">
          <div class="card-title">
            {{ item.planName }} <span class="badge badge-pill text-capitalize mx-1"
            :class="resolveStatusVariant(item.status)"
>{{ item.status }}</span>
          </div>
          <div class="text-uppercase">
            {{ item.planCategory }} - {{ item.planType }}
          </div>
        </div>
        <div class="card-body p-1">
          <div class="d-flex justify-content-between ">
            <div class="text-center">
              <span class="p-0 text-muted">Warehouse</span>
              <h5 class="p-0">
{{ item.warehouse }}
</h5>
            </div>
            <div class="text-center">
              <span class="p-0 text-muted">Start Date</span>
              <h5 class="p-0">
{{ formatDate(item.startDate) }}
</h5>
            </div>
            <div class="text-center">
              <span class="p-0 text-muted">Enrollment Date</span>
              <h5 class="p-0">
{{ formatDate(item.enrollmentDate) }}
</h5>
            </div>
            <div class="text-center">
              <span class="p-0 text-muted">Minimum</span>
              <h5 class="p-0">
{{ formatNumber(item.min) }}
</h5>
            </div>
            <div class="text-center">
              <span class="p-0 text-muted">Maximum</span>
              <h5 class="p-0">
{{ formatNumber(item.max) }}
</h5>
            </div>
            <div class="text-center">
              <span class="p-0 text-muted">Item Fee</span>
              <h5 class="p-0">
{{ currencyFormatter(item.itemFee) }}
</h5>
            </div>
          </div>
          <div v-if="item.description">
            <span class="text-muted">Description:</span>  {{ item.description }}
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { currencyFormatter, formatDate } from '@core/utils/filter'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currencyFormatter,
      formatDate,
    }
  },
  methods: {
    formatNumber(number) {
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
    resolveStatusVariant(status) {
      if (status.toLowerCase() === 'active') return 'badge-success'
      if (status.toLowerCase() === 'passive') return 'badge-secondary'
      if (status.toLowerCase() === 'pending') return 'badge-warning'
      if (status.toLowerCase() === 'rejected') return 'badge-danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
