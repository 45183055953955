/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Pager from '@core/dev-extreme/utils/pager'
import shipService from '@/http/requests/ship/shipService'
import ReceivePageSearchFilter from '@/http/models/search-filters/ReceivePageSearchFilter'

const receiveBatchStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortQuery(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    const searchFilter = new ReceivePageSearchFilter()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : searchFilter
    const response = await shipService.fetchReceivePageViewByQuery(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const receiveBatchSource = new DataSource({
  store: receiveBatchStore,
})

export {
  receiveBatchSource,
}
