<template>
  <dx-util-popup
    ref="receiveNotesPopupRef"
    height="auto"
    width="480"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="receiveNotesPopupShown"
    @hidden="receiveNotesPopupHidden"
  >
    <div>
      <dx-util-text-area v-model="newNotes" :auto-resize-enabled="true" :min-height="200" />
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'

import shipService from '@/http/requests/ship/shipService'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      newNotes: '',
    }
  },
  computed: {
    receiveNotesPopup() {
      return this.$refs.receiveNotesPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.receiveNotesPopup.show()
        this.newNotes = this.row.value
      },
    },
  },
  methods: {
    receiveNotesPopupShown() {
      this.popupTitle = 'Receive Item Notes'
    },
    receiveNotesPopupHidden() {
      // Clear form when popup is closed
    },
    closePopup() {
      this.receiveNotesPopup.hide()
    },
    handleSubmit() {
      const payload = {
        id: this.row.data.id,
        Notes: this.newNotes,
      }
      shipService.updateNotes(payload).then(() => {
        this.newNotes = ''
        this.$emit('emit-form-saved')
        this.closePopup()
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
