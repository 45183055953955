<template>
  <dx-util-popup
    ref="productPlanAssignmentPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    width="40vw"
    height="auto"
    :title="productPlanAssignmentPopupTitle"
    @shown="onShownProductPlanAssignmentPopup"
    @hidden="onHiddenProductPlanAssignmentPopup"
  >
    <dx-util-list
      id="planList"
      ref="planListRef"
      height="20vw"
      :data-source="plans"
      selection-mode="single"
      :bounce-enabled="true"
      :use-native-scrolling="true"
      :scroll-by-content="true"
      :scroll-by-thumb="true"
      :search-enabled="true"
      search-mode="contains"
      :search-expr="['planName', 'planType', 'planCategory', 'warehouse']"
      @item-click="onClickPlan"
    >
    <template #item="{ data: item }">
     <plan-details :item="item" />
    </template>
    </dx-util-list>
    <div class="text-right">
      <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
      <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@robustshell/utils/index'
import { currencyFormatter, formatDate } from '@core/utils/filter'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import Pageable from '@robustshell/components/Grid/pageable'
import planManagementService from '@/http/requests/plan/planManagementService'
import productsService from '@/http/requests/product/productsService'
import PlanDetails from './PlanDetails.vue'

export default {
  components: {
    'plan-details': PlanDetails,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      required: true,
    },
    itemList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currencyFormatter,
      formatDate,
      plans: [],
      productPlanAssignmentPopupTitle: '',
      selectedPlan: null,
      pageable: new Pageable({ pageSize: 200 }),
    }
  },
  computed: {
    pageableResult: {
      get() {
        return this.pageable.toCreatePayload()
      },
    },
    productPlanAssignmentPopupIns() {
      return this.$refs.productPlanAssignmentPopupRef.instance
    },

  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createProductPlanAssignmentPopup()
        }
      },
    },
  },
  mounted() {
    this.getPlansByQuery()
    this.getPlanTypes()
    this.getWarehouses()
  },
  methods: {
    createProductPlanAssignmentPopup() {
      this.$nextTick(() => {
        this.productPlanAssignmentPopupIns.show()
      })
    },
    onShownProductPlanAssignmentPopup() {
      this.productPlanAssignmentPopupTitle = 'Assign Plan'
    },
    onHiddenProductPlanAssignmentPopup() {
      this.resetForm()
    },
    onClose() {
      this.productPlanAssignmentPopupIns.hide()
    },
    searchByFilters() {
      this.getQueryParams()
    },
    getPlanTypes() {
      this.planTypes.push({ value: 0, text: 'Plan Type (All)' })
      planManagementService.fetchAllPlanTypes().then(result => {
        result.data.forEach(element => {
          this.planTypes.push({ value: element.id, text: element.typeName })
        })
      })
    },
    getSearchFilter() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.planType = this.selectedPlanType
      PlanSearchFilter.warehouseId = this.selectedWarehouse
      PlanSearchFilter.planCategory = this.selectedPlanCategory
      PlanSearchFilter.companyId = this.companyId
      const searchFilter = PlanSearchFilter.getFilters()
      return searchFilter
    },
    getPlansByQuery() {
      this.plans.splice(0, this.plans.length)
      planManagementService
        .fetchAllUserPlansByQuery(this.getSearchFilter(), this.pageable.toCreateQueryString())
        .then(result => {
          const data = result.data.content
          this.plans.push(...data)
        })
    },
    // Assign selected plan to selected items
    onSubmit() {
      if (this.selectedPlan) {
        const planId = this.selectedPlan.id
        productsService.assignPlan(this.itemList, planId).then(() => {
          this.$emit('on-item-saved')
          this.resetForm()
          // this.$root.$emit('bv::toggle::collapse', 'assign-plan-sidebar')
          this.onClose()
        })
      } else {
        Notify.warning('Please select a plan.')
      }
    },
    resetForm() {
      this.selectedPlan = null
      // this.$refs.refCustomerPlanListTable.clearSelected()
    },
    onSidebarHidden() {
      this.resetForm()
    },
    onClickPlan(e) {
      const item = e.itemData
      this.selectedPlan = item
    },
  },
}
</script>
