<template>
  <div class="">
    <div v-if="!editable" class="d-flex justify-content-around">
      <span>
        {{ row.value }}
      </span>
      <p-icon role="button" name="bi-pencil-square" class="text-primary" @click.native="editable = true" />
    </div>
    <dx-util-number-box
      v-else
      :ref="`textbox-quantity-${row.data.id}`"
      :value="row.value"
      :min="0"
      @content-ready="setEditable"
      @value-changed="updateQuantityInForm"
      @focus-in="onFocusInUpdate(row)"
      @focus-out="editable=false,quantityDiff=0"
      @key-down="onKeyDown($event, row)"
      @enter-key="onEnterKey"
    >
      <dx-text-box-button
        :name="`quantity-${row.data.id}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `quantity-${row.data.id}` },
          onClick: () => onUpdateQuantity(row)
          }"
        location="after"
      />
  </dx-util-number-box>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import shipService from '@/http/requests/ship/shipService'
import { Notify } from '@robustshell/utils/index'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    inventoryType: {
      type: String,
      default: 'available',
      validator: value => ['available', 'reserved', 'on-hand'].includes(value),
    },
  },
  data() {
    return {
      editable: false,
      newQuantity: null,
      quantityDiff: 0,
    }
  },
  methods: {
    updateQuantityInForm(e) {
      this.newQuantity = e.value
      this.quantityDiff = e.value - e.previousValue
    },
    setEditable(e) {
      e.component.focus()
      this.onFocusInUpdate(this.row)
    },
    onUpdateQuantity(row) {
      if (this.quantityDiff === 0) {
        Notify.info('No change has been detected.')
        return
      }
      if (this.quantityDiff < 0) {
        Notify.info('You canot decrement item quantity.')
        return
      }
      if (!row.data.id) return
      const item = { ...row.data }
      this.inventoryQuantityFormData = {
        id: item.id,
        currentCount: item.quantity,
        newCount: this.newQuantity,
      }

      shipService.changeItemQuantity(this.inventoryQuantityFormData).then(() => {
        this.editable = false
        this.quantityDiff = 0
        this.$emit('on-emit-quantity-updated')
      })
    },
    onFocusInUpdate(row) {
      this.newQuantity = row.data.quantity
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (this.editable && e.event.which === escapeKey) {
        this.editable = false
      }
    },
    onEnterKey() {
      this.onUpdateQuantity(this.row)
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
