<template>
  <div class="container-fluid">
    <assign-plan
      :item-list="itemListForPlanAssignment"
      :company-id="companyId"
      :component-id="productPlanAssignmentComponentId"
      @on-item-saved="searchByFilter"
    />
    <item-receive :component-id="itemReceiveComponentId"
      :current-item="itemToReceive" :warehouses="warehouses"
      @on-item-saved="searchByFilter"
    />
    <div class="row">
      <div class="col-md-12 px-0">

        <!--Begin:: Data table-->
        <dx-data-grid
          id="gridReceive"
          ref="receiveDataGrid"
          height="calc(100vh - 145px )"
          :data-source="receiveBatchSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :selected-row-keys="selectedRows"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="handleContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @cell-dbl-click="onCellDblClick"
          @exporting="onExporting"
          @option-changed="onOptionChanged"
          @editor-preparing="onEditorPreparing"
        >
          <dx-state-storing
            :enabled="true"
            type="custom"
            :custom-load="loadDataGridState"
            :custom-save="saveDataGridState"
          />
          <dx-sorting mode="single" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <template #buttons="{ data }">
            <div>
              <div v-for="item in data" :key="item.id" class="">
                <dx-util-button :text="item.name" :icon="item.icon" class=" w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
              </div>
            </div>
          </template>
          <dx-remote-operations :paging="true" :sorting="true" :filtering="true" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-export :enabled="true" :allow-export-selected-data="true" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-editing :confirm-delete="false" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
            <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
            <template #imageCellTemplate="{data}">
              <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
            </template>
            <dx-column data-field="title"
              width="500" cell-template="cellTitle"
              :calculate-filter-expression="calculateTitleFilterExpression"
            />
            <template #cellTitle="{ data }">
              <div>
              <receive-product-info-card :product="data.data" @emit-open-product-details="openProductDetails" />
            </div>
            </template>
            <dx-column data-field="asin" width="150" caption="ASIN" cell-template="cellASIN" />
            <dx-column data-field="altAsin" width="120" caption="Alt ASIN" :allow-sorting="false" />
            <dx-column data-field="sortedCount" caption="Sorted" width="80" cell-template="cellSortedCount" />
            <dx-column data-field="status" cell-template="statusTemplate" :allow-filtering="false" />
            <template #statusTemplate="{ data }">
              <dx-util-button
                :text="formatStatus(data.value)"
                :type="resolveStatusVariant(data)"
                :element-attr="{class: 'btn-action-xs'}"
                hint="Click this button to block/unblock items."
                width="100%"
                @click="onBlockUnblockAction(data)"
              />
            </template>
            <dx-column caption="Receive" cell-template="receiveButtonTemplate" :allow-filtering="false" />
            <template #receiveButtonTemplate="{ data }">
              <dx-util-button
                text="Receive"
                type="success"
                styling-mode="outlined"
                :element-attr="{class: 'btn-action-xs'}"
                :disabled="isStatusBlock(data)"
                width="100%"
                @click="onClickReceiveItem(data)"
              />
            </template>
            <dx-column data-field="resolution" cell-template="resolutionTemplate" :allow-filtering="false" width="140" />
            <template #resolutionTemplate="{data}">
              <div>
                <dx-util-select-box :value="data.value" :data-source="resolutionOptions" display-expr="text" value-expr="value" :class="resolveResolution(data.value)" @value-changed="updateResolutionStatus($event, data.data.id)" />
              </div>
            </template>
            <dx-column data-field="upc" caption="UPC" :width="130" cell-template="upcCellTemplate" />
            <template #upcCellTemplate="{data}">
              <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
                {{ data.value }}
              </a>
            </template>
            <dx-column data-field="msku" width="150" caption="MSKU" />
            <dx-column data-field="orderNumber" width="150" caption="Order #" />
            <dx-column data-field="orderNumberSold" width="150" caption="Order Number Sold" />
            <dx-column data-field="tracking" width="150" />
            <dx-column data-field="palletId" width="100" />
            <dx-column data-field="action" width="85" />
            <dx-column data-field="storeName" width="130" :visible="stores.length > 1" caption="Store" />
            <dx-column data-field="quantity" :width="100" caption="QTY" cell-template="quantityTemplate" />
            <dx-column data-field="availableCount" caption="Remaining" width="90" cell-template="cellAvailableCount" />
            <dx-column data-field="receivedCount" width="85" caption="Received" />
            <dx-column data-field="damagedCount" width="85" caption="Problem" />
            <dx-column data-field="shippedCount" width="80" caption="Shipped" />
            <dx-column data-field="countInBundle" width="65" caption="B/MP" cell-template="cellCountInBundle" header-cell-template="header-count-in-bundle" />
            <dx-column data-field="countPerBundle" width="75" caption="C/B,MP" cell-template="cellCountPerBundle" header-cell-template="header-count-per-bundle" />
            <dx-column data-field="fnsku" width="110" caption="FNSKU" />
            <dx-column data-field="expirationDate" caption="Exp Date" data-type="date" />
            <dx-column data-field="creationTime" caption="Upload Date" data-type="date" />
            <dx-column data-field="notes" width="350" caption="Notes" cell-template="cellNotes" />
            <dx-column data-field="tags" />
            <dx-column data-field="purchasedBy" />
            <dx-column data-field="source" />
            <template #quantityTemplate="{data}">
              <edit-receive-quantity :row="data" @on-emit-quantity-updated="searchByFilter" />
            </template>

            <dx-scrolling column-rendering-mode="standard" />
            <template #cellASIN="{ data }">
              <div class="d-flex flex-sm-row justify-content-between align-items-center mb-0">
                <a :href="`https://www.amazon.com/gp/product/${data.value}`" target="_blank">
                  {{ data.value }}
                </a>
                <b-button
                  v-if="data.value"
                  v-b-tooltip.d800.hover.top.v-secondary="'Copy ASIN'"
                  variant="flat-info"
                  class="btn-sm btn-icon"
                  @click="copyContext(data.value)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </div>
            </template>
            <template #cellSortedCount="{ data }">
              <span v-if="data.value > 0" class="d-block badge badge-light-warning">
                {{ data.value }}
              </span>
            </template>
            <template #cellAvailableCount="{ data }">
              <span v-if="data.value > 0" class="d-block badge badge-light-success">
                {{ data.value }}
              </span>
            </template>
            <template #cellCountInBundle="{ data }">
              <span v-if="data.value > 0" class="d-block badge badge-light-danger">
                {{ data.value }}
              </span>
            </template>
            <template #cellCountPerBundle="{ data }">
              <span v-if="data.value > 0" class="d-block badge badge-light-danger">
                {{ data.value }}
              </span>
            </template>
            <template #header-count-in-bundle="{ data }">
              <div v-b-tooltip.bottom title="Count used in Bundle/MultiPack">
                {{ data.column.caption }}
              </div>
            </template>
            <template #header-count-per-bundle="{ data }">
              <div v-b-tooltip.bottom title="Count Per Bundle/MultiPack">
                {{ data.column.caption }}
              </div>
            </template>
            <template #cellNotes="{ data }">
              <div v-b-tooltip="data.value" class="text-truncate" style="min-width: 200px">
                <p-icon role="button" name="bi-pencil-square" class="text-primary ml-half" @click.native="openNotesPopup(data)" />
                <span v-if="checkBundle(data.value)" style="color: orange;">
                  {{ data.value }}
                </span>
                  <span v-else>
                  {{ data.value }}
                </span>
              </div>
            </template>
            <template #cellPackType="{ data }">
              <span
                v-if="data.value === 'individual'"
                v-b-tooltip.d800.hover.left.v-secondary="'Individual pack'"
                class="d-block badge badge-light-secondary"
                >
                Single
              </span>
              <span
                v-else-if="data.value === 'bundle'"
                class="d-block badge badge-light-secondary"
                >
                Bundle
              </span>
              <span
                v-else-if="data.value === 'multi_pack'"
                v-b-tooltip.d800.hover.left.v-secondary="'Multi Pack'"
                class="d-block badge badge-light-secondary"
                >
                MP
              </span>
              <span
                v-else
                v-b-tooltip.d800.hover.left.v-secondary="'Bundle or Multi-pack parts'"
                class="d-block badge badge-light-secondary"
                >
                Part
              </span>
            </template>
            <template #toolbarAfter>
              <div class="d-flex flex-row align-items-center">
                <div class="mr-2">
                  <dx-util-select-box
                    v-model="selectedStore"
                    :search-enabled="true"
                    :data-source="stores"
                    search-mode="contains"
                    search-expr="text"
                    :search-timeout="200"
                    :min-search-length="4"
                    :show-data-before-search="true"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="onStoreSelect"
                  />
                </div>
                <div v-if="isServingTenant" id="userPlans" class="mr-2">
                  <dx-util-select-box
                    v-model="selectedUserPlan"
                    :data-source="userPlans"
                    :drop-down-options="selectBoxAttributes"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="onPlanSelect"
                  />
                </div>
                <div class="mr-2">
                  <dx-util-select-box
                    v-model="selectedPackType"
                    :data-source="packTypes"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div class="mr-2">
                  <dx-util-select-box
                    v-model="selectedItemAction"
                    :data-source="itemActionTypes"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="onItemActionSelect"
                  />
                </div>
                <h4 v-if="isServingTenant" class="text-success mt-0 mb-0 mr-2">
                  Acc or Suite #: {{ accountNo }}, {{ companyName }}
                </h4>
              </div>
            </template>
          </dx-data-grid>
          <!--End:: Data table-->
        </div>
        <div class="col-md-12 px-0">
          <light-box
            :items="images"
            :index="index"
            :effect="'fade'"
            @close="index = null"
          />
        </div>
        <div>
          <dx-util-popup
            ref="itemStatusUpdatePopupRef"
            :show-close-button="false"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="true"
            content-template="content"
            :title="itemStatusUpdateTitle"
            :width="560"
            :height="320"
            @shown="updatePopupTitle"
          >
            <dx-util-position at="center" my="center" />
            <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelItemStatusButtonOptions" />
            <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveItemStatusButtonOptions" />
            <form method="post" @submit="handleBlockUnblockItemStatus">
              <dx-util-form ref="itemStatusUpdatePopupFormRef" :form-data="itemStatusUpdatePopupForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="companyData">
                <dx-util-item data-field="notes" :editor-options="{ height: 160 }" editor-type="dxTextArea" :label="{text: 'Notes'}">
                  <dx-util-required-rule message="Notes is required" />
                </dx-util-item>
              </dx-util-form>
            </form>
          </dx-util-popup>
        </div>
      </div>
    <receive-item-notes :component-id="receiveNotesCompId" :row="selectedItem" @emit-form-saved="searchByFilter()" />
    <plan-subscription-details :component-id="planSubscriptionDetailsComponentId" :plan-id="rowPlanId" />
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isVisible"
      @close="isVisible=false,selectedProductId=null"
    />
    <!--End:: Product Details -->
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import store from '@/store'
import commonEntityService from '@/http/requests/common/commonEntityService'
import shipService from '@/http/requests/ship/shipService'
import ReceivePageSearchFilter from '@/http/models/search-filters/ReceivePageSearchFilter'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import planManagementService from '@/http/requests/plan/planManagementService'
import { Notify } from '@robustshell/utils/index'
import { useClipboard } from '@vueuse/core'
import ReceiveItemNotes from '@/views/apps/inbound/receive-batch/components/ReceiveItemNotes.vue'
import ItemActionTypeEnum from '@/enums/itemActionTypeEnum'
import { getAmzResolutionList } from '@/enums/amzResolution.enum'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import CoolLightBox from 'vue-cool-lightbox'
import { v4 as uuidv4 } from 'uuid'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import Pager from '@/@core/dev-extreme/utils/pager'
import useCurrentUser from '@/libs/app/current-user'
import AssignPlan from './AssignPlan.vue'
import EditReceiveQuantity from './EditReceiveQuantity.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { ClientShipmentItemStatusEnum, DataGridNamesEnum } from '~/enums'
import { receiveBatchSource } from './receiveBatchStore'
import ReceiveProductInfoCard from './components/ReceiveProductInfoCard'
import {
  isValidAsin,
  isValidUpc,
} from '../../ship/validationItem'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'assign-plan': AssignPlan,
    'light-box': CoolLightBox,
    'receive-product-info-card': ReceiveProductInfoCard,
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'plan-subscription-details': () => import('@/views/apps/plan-management/plans/PlanSubscriptionDetails.vue'),
    'item-receive': () => import('./ItemReceive.vue'),
    'edit-receive-quantity': EditReceiveQuantity,
    'receive-item-notes': ReceiveItemNotes,
  },
  mixins: [GridBase],
  setup() {
    const {
      userId,
      userCompanyId,
      isServingTenant,
    } = useCurrentUser()
    const { copy } = useClipboard()
    const copyContext = context => {
      copy(context)
    }

    return {
      userId,
      userCompanyId,
      isServingTenant,
      copyContext,
    }
  },
  data: () => ({
    receiveBatchSource,
    selectBoxAttributes: {
      minWidth: '400px',
    },
    searchText: '',
    isSearchAction: false,
    packType: '',
    itemToReceive: {},
    selectedItems: [],
    bundleItems: [],
    outlined: 'outlined',
    buttonAttributes: {
      class: 'btn btn-sm btn-success',
    },
    selectedItem: {},
    selectedRows: [],
    gridOptions: {
      allMode: 'allPages',
      checkBoxesMode: 'always',
    },
    accountNo: '',
    companyId: 0,
    companyName: '',
    userPlans: [],
    selectedUserPlan: 0,
    selectedStore: 0,
    stores: [],
    warehouses: [],
    bundleKeywords: ['bundle', 'multipack', 'pack', 'combine', 'set'],
    statuses: [
      { name: 'Intransit,Unloaded', value: 0 },
      { name: 'Sorted', value: 2 },
    ],
    packTypes: [
      { text: 'PackType (All)', value: 'all' },
      { text: 'Single', value: 'individual' },
      { text: 'Bundle', value: 'bundle' },
      { text: 'Multi-Pack', value: 'multi_pack' },
    ],
    selectedPackType: 'all',
    itemListForPlanAssignment: [],
    productInfoModal: {
      id: 0,
      title: '',
      content: {},
    },
    images: [],
    index: null,
    itemStatusUpdateTitle: '',
    itemStatusUpdateBool: true,
    itemStatusUpdatePopupForm: {
      productId: 0,
      status: 0,
      notes: '',
    },
    defaultImg: defaultImageUrl,
    selectedProductId: null,
    isVisible: false,
    planSubscriptionDetailsComponentId: '',
    productPlanAssignmentComponentId: '',
    rowPlanId: null,
    itemReceiveComponentId: '',
    isGridLoaded: false,
    selectedItemAction: 12,
    currentGridStateId: null,
    dataGridState: {},
    resolutionOptions: getAmzResolutionList(),
    receiveNotesCompId: '',
  }),
  computed: {
    dataGrid() {
      const dataGrid = this.$refs.receiveDataGrid.instance
      return dataGrid
    },
    itemActionTypes() {
      const actionTypes = { ...ItemActionTypeEnum }
      actionTypes.wfs.disabled = false
      const actionTypesList = Object.values(actionTypes)

      actionTypesList.unshift({ text: 'All Actions', value: 12 })
      return actionTypesList
    },
    saveItemStatusButtonOptions() {
      return {
        text: this.itemStatusUpdateBool ? 'Block' : 'Unblock',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleBlockUnblockItemStatus()
        },
      }
    },
    cancelItemStatusButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.itemStatusUpdatePopupRef.instance
          popup.hide()
        },
      }
    },
    fragileCount() {
      return this.selectedItems.filter(el => el.fragile === true)?.length
    },
  },
  created() {
    const { companyName } = store.getters['receiveBatch/getSelectedAccountInfo']
    this.companyName = companyName
    this.accountNo = +this.$route.params.accountNo
    this.companyId = +this.$route.params.companyId
    this.selectedItemAction = this.$route.params.action || 12
    const filters = this.getSearchFilter()
    receiveBatchSource.searchValue(filters)
  },
  async mounted() {
    await this.getStores()
    await this.getUserPlans()
    await this.getWarehouses()
  },
  methods: {
    customizeColumns(columns) {
      columns.forEach(column => {
        column.cssClass = 'vertical-middle'
      })
    },
    resolveStatusVariant(data) {
      if (data.data.status === ClientShipmentItemStatusEnum.fulfilled.key || data.data.status === ClientShipmentItemStatusEnum.shipped.key) return 'success'
      if (data.data.status === ClientShipmentItemStatusEnum.archived.key || data.data.status === ClientShipmentItemStatusEnum.intransit.key) return 'normal'
      if (data.data.status === ClientShipmentItemStatusEnum.blocked.key) return 'danger'
      return 'default'
    },
    formatStatus(status) {
      return ClientShipmentItemStatusEnum[status].text
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    checkBundle(notes) {
      for (let i = 0; i < this.bundleKeywords.length; i++) {
        if (notes && notes.toLowerCase().indexOf(this.bundleKeywords[i]) > -1) {
          return true
        }
      }
      return false
    },
    isPlanAssignmentActionVisible(e) {
      return !e.row.data.planId
    },
    isPlanActionVisible(e) {
      return !!e.row.data.planId
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.searchText = ''
        this.searchText = e.value
      }
      if (e.fullName === 'paging.pageSize') {
        receiveBatchSource.pageIndex(0)
      }
    },
    handleContentReady(e) {
      this.onContentReady(e)
      this.isGridLoaded = true
    },
    onStoreSelect(e) {
      const selectedStoreId = e.value
      this.$router.push({ name: 'route-receive-batch', params: { storeId: selectedStoreId } }).catch(() => {})
      if (this.isGridLoaded) {
        this.searchByFilter()
      }
    },
    onPlanSelect(e) {
      const selectedPlanId = e.value
      this.$router.push({ name: 'route-receive-batch', params: { planId: selectedPlanId } }).catch(() => {})
      if (this.isGridLoaded) {
        this.searchByFilter()
      }
    },
    onItemActionSelect(e) {
      const selectedAction = e.value
      this.$router.push({ name: 'route-receive-batch', params: { action: selectedAction } }).catch(() => {})
      if (this.isGridLoaded) {
        this.searchByFilter(584)
      }
    },
    async setActionByItem(item) {
      switch (item.id) {
        case 'resetDatagridState':
          this.resetGridState()
          break
        default:
          break
      }
    },
    async resetGridState() {
      if (this.currentGridStateId === null) {
        Notify.warning('This datagrid does not have any settings to reset.')
        return
      }
      await settingsService.delete(this.currentGridStateId)
      window.location.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarAfter',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Receive Items',
          icon: 'upload',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            const dataGrid = this.$refs.receiveDataGrid.instance
            this.selectedItems = dataGrid.getSelectedRowsData()
            if (this.selectedItems.length === 0) {
              Notify.warning('Please select at least 1 item to receive')
              return
            }
            if (this.selectedUserPlan === 0) {
              Notify.warning('Please select a plan')
              return
            }
            this.receiveItemsByIdList()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          hint: 'Search more on server',
          icon: 'find',
          stylingMode: 'outlined',
          type: 'success',
          onClick: () => {
            if (this.searchText) {
              this.searchByFilter()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Plan',
          visible: this.isServingTenant,
          icon: 'link',
          stylingMode: 'outlined',
          // type: 'success',
          onClick: () => {
            const dataGrid = this.$refs.receiveDataGrid.instance
            const selectedRowData = dataGrid.getSelectedRowsData()
            if (selectedRowData.length !== 0) {
              this.itemListForPlanAssignment.splice(0, this.itemListForPlanAssignment.length)
              selectedRowData.forEach(element => {
                if ('productId' in element) {
                  this.itemListForPlanAssignment.push(element.productId)
                }
              })
              this.$root.$emit('bv::toggle::collapse', 'assign-plan-sidebar')
              this.productPlanAssignmentComponentId = uuidv4()
            } else {
              Notify.warning('Please select items to assign a plan.')
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.searchByFilter()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          icon: 'menu',
          showArrowIcon: false,
          items: [
            {
              id: 'resetDatagridState',
              name: 'Reset Datagrid Settings',
              icon: 'icon bi-x-square',
            },
          ],
          width: 36,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Set Preferences',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'buttons',
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      this.isSearchAction = true
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.searchText = query.trim()
          e.component.searchByText('')
          this.searchByFilter()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.searchText = query
          this.searchByFilter()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.searchText = ''
          this.searchByFilter()
        }
      }
    },
    calculateTitleFilterExpression(filterValue) {
      const words = filterValue.split(/[ ,]+/)
      const filter = []
      words.forEach(word => {
        filter.push(['title', 'contains', word])
        filter.push('and')
      })
      filter.pop()
      return filter
    },
    searchByFilter() {
      const filters = this.getSearchFilter()
      receiveBatchSource.searchValue(filters)
      receiveBatchSource.reload()
    },
    openNotesPopup(data) {
      this.receiveNotesCompId = this.$uid()
      this.selectedItem = data
    },
    getSearchFilter() {
      const searchFilter = new ReceivePageSearchFilter()
      searchFilter.companyId = this.companyId
      searchFilter.storeId = this.selectedStore
      searchFilter.packType = this.selectedPackType
      searchFilter.upc = isValidUpc(this.searchText)
      searchFilter.asin = isValidAsin(this.searchText)
      searchFilter.q = this.searchText
      searchFilter.itemAction = this.selectedItemAction || 12
      return searchFilter
    },
    receiveItemsByIdList() {
      const totalItemCount = this.selectedItems.reduce((a, b) => a + (b.quantity || 0), 0)
      this.$swal({
        title: 'Confirm Item Receive',
        html: `<table class="table table-bordered table-sm text-left bg-white">
          <tbody>
            <tr>
              <th scope="row">Row Count</th>
              <td>${this.selectedItems.length}</td>
            </tr>
            <tr>
              <th scope="row">Total Item Count</th>
              <td>${totalItemCount}</td>
            </tr>
            <tr>
              <th scope="row">Fragile Count</th>
              <td>${this.fragileCount}</td>
            </tr>
          </tbody>
        </table>`,
        icon: 'info',
        width: '30vw',
        showCancelButton: true,
        confirmButtonText: 'Receive',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          content: 'px-0',
          htmlContainer: 'mx-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const items = []
          this.selectedItems.map(item => items.push(item.id))
          shipService.receiveItemsByIdList(items, this.selectedUserPlan)
            .then(() => {
              this.dataGrid.clearSelection()
              this.searchText = ''
              this.searchByFilter()
            })
        }
      })
    },
    getUserPlans() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = this.selectedWarehouse
      PlanSearchFilter.companyId = this.companyId
      const searchFilter = PlanSearchFilter.getFilters()
      const pager = new Pager()
      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pager.staticPageable)
        .then(result => {
          const data = result.data.content
          if (data.length > 1) {
            this.userPlans.push({ value: 0, text: 'Plans (All)' })
          }
          data.forEach(element => {
            if (element.companyId === this.companyId) {
              this.userPlans.push({
                value: element.id,
                text: `${element.planCategory.toUpperCase()}:${element.planName}:${element.planType}`,
              })
            }
          })
          if (this.userPlans.length === 1) {
            this.selectedUserPlan = this.userPlans[0].value
            this.$router.push({ name: 'route-receive-batch', params: { planId: this.selectedUserPlan } }).catch(() => {})
          }
        })
    },
    getStores() {
      commonEntityService
        .fetchStoresByParentCompany(this.companyId)
        .then(result => {
          const data = result.data
          if (data.length !== 1) {
            this.stores.push({ value: 0, text: 'Store (All)' })
          }
          data.forEach(element => {
            this.stores.push({ value: element.id, text: element.name })
          })
          if (this.stores.length === 1) {
            this.selectedStore = this.stores[0].value
            this.$route.params.storeId = this.selectedStore
          }
        })
    },
    getWarehouses() {
      shipService.fetchWarehouses().then(result => {
        const { data } = result
        if (data.length > 1) {
          this.warehouses.push({ value: 0, text: 'Location (All)' })
        }
        result.data.forEach(element => {
          if (element.name.toLowerCase() !== 'common') {
            this.warehouses.push({ value: element.id, text: element.name })
          }
        })
        this.selectedWarehouse = 0
        if (this.warehouses.length === 1) {
          this.selectedWarehouse = this.warehouses[0].value
        }
      })
    },
    onClickAssignPlan(e) {
      const item = { ...e.row.data }
      if (item !== null && item !== 'undefined' && 'productId' in item) {
        this.itemListForPlanAssignment.splice(
          0,
          this.itemListForPlanAssignment.length,
        )
        this.itemListForPlanAssignment.push(item.productId)
      } else {
        return
      }
      this.productPlanAssignmentComponentId = uuidv4()
    },
    onClickReceiveItem(row) {
      const context = { ...row.data }
      this.itemToReceive = context
      if (this.itemToReceive.companyId !== this.companyId) {
        Notify.error(`This item does not belong to ${this.companyName}. Please report this issue to system administrator.`)
        return
      }
      if (!('packType' in this.itemToReceive) || (this.itemToReceive.packType === null) || (this.itemToReceive.packType === '') || (this.itemToReceive.packType === 'null')) {
        this.packType = 'Bundle Part'
        this.selectedItem = this.itemToReceive
        this.itemReceiveComponentId = uuidv4()
      } else {
        if (!this.itemToReceive.productId || this.itemToReceive.productId === 0) {
          Notify.warning('Please assign a product first before receiving this item.')
          return
        }
        if (this.selectedUserPlan && this.selectedUserPlan !== 0) {
          this.itemToReceive.planId = this.selectedUserPlan
        } else if (!this.itemToReceive.planId || this.itemToReceive.planId === null || this.itemToReceive.planId === '' || this.itemToReceive.planId === 0) {
          Notify.warning('Please assign a plan first before receiving this item.')
          return
        }
        if (this.itemToReceive.packType === 'individual') {
          this.packType = 'Item'
        } else if (this.itemToReceive.packType === 'bundle') {
          this.packType = 'Bundle'
        } else if (this.itemToReceive.packType === 'multi_pack') {
          this.packType = 'Multi Pack'
        }
        this.selectedItem = this.itemToReceive
        this.itemReceiveComponentId = uuidv4()
      }
    },
    resolveResolution(resolution) {
      if (resolution === 'OPEN') return 'bg-primary'
      if (resolution === 'RESOLVED') return 'bg-success'
      if (resolution === 'CASE_INITIATED') return 'bg-warning'
      return ''
    },
    onClickPlanInfo(e) {
      this.planSubscriptionDetailsComponentId = uuidv4()
      const { planId } = e.row.data
      this.rowPlanId = planId
    },
    isStatusBlock(row) {
      const data = { ...row.data }
      return data.status === ClientShipmentItemStatusEnum.blocked.key
    },
    onBlockUnblockAction(row) {
      const data = { ...row.data }
      const formData = {
        productId: 0,
        status: 0,
        notes: '',
      }
      this.itemStatusUpdatePopupForm = { ...formData }
      const popup = this.$refs.itemStatusUpdatePopupRef.instance
      this.itemStatusUpdatePopupForm.productId = data.productId
      if (data.status !== ClientShipmentItemStatusEnum.blocked.key) {
        this.itemStatusUpdateBool = true
        this.itemStatusUpdatePopupForm.notes = 'This item can not be replenished to Amazon due to the Storage Limits.'
        this.itemStatusUpdatePopupForm.status = ClientShipmentItemStatusEnum.blocked.value
      } else {
        this.itemStatusUpdateBool = false
        this.itemStatusUpdatePopupForm.notes = 'This item is now eligible to replenish to Amazon due to available storage capacity.'
        this.itemStatusUpdatePopupForm.status = ClientShipmentItemStatusEnum.intransit.value
      }
      popup.show()
    },
    async handleBlockUnblockItemStatus() {
      const form = this.$refs.itemStatusUpdatePopupFormRef.instance
      const popup = this.$refs.itemStatusUpdatePopupRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        try {
          await shipService.updateItemStatus(this.itemStatusUpdatePopupForm)
        } catch (err) {
          Notify.error(err.message)
        }
        popup.hide()
        this.searchByFilter()
      }
    },
    openProductDetails(e) {
      this.selectedProductId = e.productId
      this.isVisible = true
    },
    updatePopupTitle() {
      this.itemStatusUpdateTitle = this.itemStatusUpdateBool ? 'Block' : 'Unblock'
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.INBOUND_RECEIVE_BATCH_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        this.dataGridState = state
      }
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }
      if (JSON.stringify(e) === JSON.stringify(this.dataGridState)) {
        return
      }
      if (e.searchText !== '' || this.isSearchAction) {
        this.isSearchAction = false
        return
      }

      const dataGridPreferences = { ...e }
      dataGridPreferences.selectedRowKeys = []
      dataGridPreferences.searchText = ''
      const dataGridKey = DataGridNamesEnum.INBOUND_RECEIVE_BATCH_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(dataGridPreferences),
      }
      const result = await settingsService.createOrUpdate(preferenceData)
      this.currentGridStateId = result.data.body.id
    },
    async updateResolutionStatus(e, id) {
      const status = e.value
      await shipService.resolveItemStatus(id, status)
      this.searchByFilter()
    },
  },
}
</script>

<style lang="scss">
#gridReceive {
  .vertical-middle {
    vertical-align: middle;
  }
}
</style>
