export class ReceivePageSearchFilter {
  constructor(
    companyId = 0, //
    storeId = 0, //
    warehouseId = 0, //
    batchId = 0,
    packType = 'all',
    upc = false,
    asin = false,
    includeSortedItems = false,
    includeAllBatches = false,
    showReservedItems = false,
    itemAction = 'FBA',
    q = '', //
  ) {
    this.companyId = companyId
    this.storeId = storeId
    this.warehouseId = warehouseId
    this.batchId = batchId
    this.packType = packType
    this.upc = upc
    this.asin = asin
    this.includeSortedItems = includeSortedItems
    this.includeAllBatches = includeAllBatches
    this.showReservedItems = showReservedItems
    this.itemAction = itemAction
    this.q = q
  }
}
export default ReceivePageSearchFilter
