<template>
  <div class="dx-product-title" @click="openProductDetails">
    <span class="text-info" role="button" style="font-size: 15px;">
      {{ product.title }}
    </span>
    <div class="d-flex justify-content-start mt-1 align-items-end">
      <span>
        Packing:
      </span>
      <div class="col-2 px-half">
        <span
          v-if="product.packType === 'individual'"
          v-b-tooltip.d800.hover.left.v-secondary="'Individual pack'"
          class="d-block badge badge-light-secondary"
          >
          Single
        </span>
        <span
          v-else-if="product.packType === 'bundle'"
          class="d-block badge badge-light-danger"
          >
          Bundle
        </span>
        <span
          v-else-if="product.packType === 'multi_pack'"
          v-b-tooltip.d800.hover.left.v-secondary="'Multi Pack'"
          class="d-block badge badge-light-danger"
          >
          MP
        </span>
        <span
          v-else
          v-b-tooltip.d800.hover.left.v-secondary="'Bundle or Multi-pack parts'"
          class="d-block badge badge-light-danger"
          >
          Part
        </span>
      </div>
      <!-- <div class="col-2 px-half">
        Hazmat: <p-icon name="psh-flame" :color="product.isHazmat ? 'danger': 'gray'" :size="product.isHazmat ? '28px': '24px'" class="mr-4" />
      </div> -->
      <div class="col-4 px-half">
        Condition: <span v-if="product.itemCondition" class="badge" :class="`badge-light-${resolveConditionVariant(product.itemCondition)}`">{{ product.itemCondition }}</span>
      </div>
      <div class="col-2 px-half">
        <span v-if="product.serialNo" class="badge badge-light-secondary">Serial No: {{ product.serialNo }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openProductDetails() {
      this.$emit('emit-open-product-details', this.product.id)
    },
    resolveConditionVariant(condition) {
      if (condition === 'NEW') return 'secondary'
      return 'warning'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
